import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap

document.addEventListener('turbo:load', function(){
  var toastElList = [].slice.call(document.querySelectorAll('.toast'))
  var toastList = toastElList.map(function (toastEl) {
    var toast = new bootstrap.Toast(toastEl, {
      animation: true,
      autohide: true,
      delay: 7000
    })
    toast.show()
  })
})

